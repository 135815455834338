import { Character, LANGUAGE, STATUS_NFT_2ND, WINDOWN_SIZE } from 'constants/common';
import { PAYMENT_PUBLIC_KEY } from 'constants/envs';
import { t } from 'i18next';
import JSEncrypt from 'jsencrypt';
import { NftImagesRes } from 'models/common';
import { NFT_IMAGE_TYPE } from 'models/nft';

export const enCodeEncrypt = (content) => {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(PAYMENT_PUBLIC_KEY.replace(/\\n/g, '\n'));
  return encrypt.encrypt(content);
};

export const capitalizeString = (str: string): string => {
  if (!str) return '';

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const getMarkColor = (mark: number): string => {
  if (mark >= 8) return 'green';
  if (mark >= 4) return 'goldenrod';
  return 'red';
};

export const isMobileLayout = (size: number) => {
  return size <= WINDOWN_SIZE.TABLET;
};

export const getDecodeNftUrl = (path: string | undefined) => {
  const result: any = {};
  if (`${path}`.includes(':')) {
    result.id = `${path}`.split(':')[0];
    result.typeNft = `${path}`.split(':')[1];
  }
  return result;
};

export const renderPreview = (images: NftImagesRes[] | undefined, type: NFT_IMAGE_TYPE) => {
  const image = images?.filter((item) => item.type === type);
  return image?.[0]?.objectKey;
};

export const formatNumber = (number = 0, lang: string = LANGUAGE.JAPAN) => {
  const currCurrentcy = '￥';
  const isYen = lang === LANGUAGE.JAPAN
  const regex = /[.]\d+/;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2, // limit the maximum price of decimal places after comma & rounded
  });

  // uncomment this code if you want to display decimal format
  // const value = formatter.format(number);
  const value = isYen ? currCurrentcy + number : formatter.format(number);  
  if (Number.isNaN(value)) return 0;
  // uncomment this code if you want to display decimal format
  // return lang === LANGUAGE.JAPAN ? value.replace('$', currCurrentcy) : value;
  return value;
};


export const linkifyParagraph = (text = '') => {
  /* eslint-disable no-useless-escape */
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};

export const getCharacterName = (name = '') => {
  const fName = name.toLowerCase();

  if (fName === Character.Fya) {
    return t('common.freyja');
  } else if (fName === Character.Sylphy) {
    return t('common.shylphy');
  } else if (fName === Character.BerilPawn) {
    return t('common.berilPawn');
  } else if (fName === Character.BerilKnight) {
    return t('common.berilKnight');
  } else if (fName === Character.BerilBishop) {
    return t('common.berilBishop');
  } else if (fName === Character.BerilLuke) {
    return t('common.berilLuke');
  } else if (fName === Character.ShadePawn) {
    return t('common.shadePawn');
  }else if (fName === Character.ShadeKnight) {
    return t('common.shadeKnight');
  }else if (fName === Character.ShadeBishop) {
    return t('common.shadeBishop');
  }else if (fName === Character.ShadeLuke) {
    return t('common.shadeLuke');
  }else if (fName === Character.BerilKing) {
    return t('common.berilKing');
  }else if (fName === Character.BerilQueen) {
    return t('common.berilQueen');
  }else if (fName === Character.ShadeKing) {
    return t('common.shadeKing');
  }else if (fName === Character.ShadeQueen) {
    return t('common.shadeQueen');
  }else if (fName === Character.Indra) {
    return t('common.indra');
  }

  return '';
};

export const REGEX_PHONE_NUMBER = /^[+]?[(]?[+0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/;

// export const regexPhone = /^[\+\d]?(?:[\d-.\s()]*)$/;

export const regexPhone = /^[+(]?\d*[+\d-()]*$/;


export const onChangeValueInputNumber = (
  value: string | number,
  maxValue: number,
  action: any,
  isDeleteDot?: boolean
) => {
  if (value === '.') return action('');
  let number = value
    .toString()
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1');
  if (isDeleteDot) {
    number = number.replace(/\./g, '');
  }
  if (Number(number) >= maxValue) {
    number = number.slice(0, -1);
  }
  action(number === '0' ? 0 : number);
};

export const changeStatusNft2ndMutiLanguage = (status = "", lang: string) => {
  if (lang === LANGUAGE.JAPAN) {
    if (status === STATUS_NFT_2ND.PENDING) {
      return "処理中";
    }
    if (status === STATUS_NFT_2ND.REVIEW) {
      return "処理中";
    }
    if (status === STATUS_NFT_2ND.REJECT) {
      return "取り下げ";
    }
    return "Mint";
  }
  if (status === STATUS_NFT_2ND.REJECT) {
    return status + 'ED';
  }
  return status;
}

